import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ElRefDirective } from '@app/_directives/el-ref/el-ref.directive';
import { createRxValue, distinctUntilChangedJson } from '@app/_helpers/utils';
import startWithDefault from '@app/_operators/startWithDefault';
import { CustomValidators } from '@app/_validators/custom-validators';
import { AppService } from '@app/app.service';
import {
  parseDurationAsFormat,
  parseDurationSeconds,
} from '@app/components/duration-input-control/duration-input-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns-tz/esm/fp';
import { intervalToDuration, isAfter, isValid, parse, startOfDay } from 'date-fns/esm';
import { flow } from 'lodash-es';
import { EMPTY, combineLatest, defer, of } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Logger, MyTimesService, Project, ProjectsQuery, TagType, Task, Time, UserSettingsQuery } from 'timeghost-api';

import {
  ClientProjectDialogData,
  ClientProjectPickerDialogComponent,
} from '../dialogs/client-project-picker-dialog/client-project-picker-dialog.component';
import { TagDialogData, TagPickerDialogComponent } from '../dialogs/tag-picker-dialog/tag-picker-dialog.component';
import { SelectMode } from '../record-toolbar/record-toolbar.component';
import { RecordToolbarService } from '../record-toolbar/record-toolbar.service';
import { TimeDatePickerConfig } from '../time-date-picker/time-date-picker-config';
import { TimeDatePickerComponent } from '../time-date-picker/time-date-picker.component';
import { TimeTrackCreateData } from '../time-tracker-calendar-create-dialog/time-tracker-calendar-create-dialog.component';
import { TimeTrackerCalendarStepperCreateDialogComponent } from '../time-tracker-calendar-stepper-create-dialog/time-tracker-calendar-stepper-create-dialog.component';
import { TimeTrackerCalendarUpdateDialogComponent } from '../time-tracker-calendar-update-dialog/time-tracker-calendar-update-dialog.component';

const log = new Logger('TimeTrackerEntryComponent');
const createTimeValidators = () => [Validators.required, CustomValidators.validDateFormat('HH:mm')];
const disabledFlags = ['comego'];
@UntilDestroy()
@Component({
  selector: 'app-time-tracker-entry',
  templateUrl: './time-tracker-entry.component.html',
  styleUrls: ['./time-tracker-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'time-entry-host',
  },
})
export class TimeTrackerEntryComponent implements OnInit, AfterContentInit, AfterViewInit {
  isMobile$ = this.appService.isMobile$;
  group = new FormGroup({
    name: new FormControl<string>(null),
    project: new FormControl(null, [Validators.required]),
    tags: new FormControl([]),
    billable: new FormControl(false),
    task: new FormControl(null),
    time: new FormGroup(
      {
        start: new FormControl('00:00'),
        end: new FormControl('00:00'),
        duration: new FormControl('00:00'),
      },
      {
        updateOn: 'blur',
      },
    ),
  });
  groupValue$ = combineLatest([
    this.group.valueChanges.pipe(startWith(this.group.value)),
    this.group.statusChanges.pipe(startWithDefault()),
  ]).pipe(
    untilDestroyed(this),
    debounceTime(50),
    map(([v]) => v),
  );

  private _stateVisibleMode = createRxValue(false);
  public get stateVisibleMode(): boolean {
    return this._stateVisibleMode.value;
  }
  public set stateVisibleMode(v: boolean) {
    this._stateVisibleMode.value = v;
  }
  public stateVisibleMode$ = this._stateVisibleMode.asObservable();

  toggleStateVisibleMode() {
    const v = this.stateVisibleMode;
    this.stateVisibleMode = !v;
    if (this.mode === 'range_optional' && v) {
      (this.group.get('time') as UntypedFormGroup).patchValue({
        start: '00:00',
        end: '00:00',
      });
      this.group.updateValueAndValidity();
    }
    this.cdref.detectChanges();
  }
  private _entry = createRxValue<Time>(null);
  readonly entry$ = this._entry.asObservable().pipe(distinctUntilChangedJson(), untilDestroyed(this));
  readonly entry$disabled = this.entry$.pipe(
    map((x) => !!x.currentUserCanEdit),
    untilDestroyed(this),
  );
  readonly entry$timeDiff = this.entry$.pipe(
    map((x) => ~~x?.timeDiff),
    untilDestroyed(this),
  );
  get entry() {
    return this._entry.value;
  }
  @Input()
  set entry(val: Time) {
    this._entry.next(val);
    this.prepareForm(val);
  }
  @Input() allowEdit: boolean = true;

  get timeDiff() {
    return ~~this.entry?.timeDiff;
  }
  get mode() {
    return this.userSettingsQuery.getValue()?.workspace.settings?.timesMode;
  }

  private _isLoading: boolean;
  public get isLoading(): boolean {
    return this._isLoading;
  }
  public set isLoading(v: boolean) {
    this._isLoading = v;
    this.group[v ? 'disable' : 'enable']();
    this.cdref.markForCheck();
  }
  readonly projectView$ = this.group.valueChanges.pipe(
    map((x) => {
      if (!x.project) return null;
      return x;
    }),
    untilDestroyed(this),
  );
  constructor(
    private ref: ElementRef,
    private myTimesService: MyTimesService,
    private projectsQuery: ProjectsQuery,
    private i18nService: TranslateService,
    private appService: AppService,
    private userSettingsQuery: UserSettingsQuery,
    private cdref: ChangeDetectorRef,
    private recordService: RecordToolbarService,
    private dialog: MatDialog,
    private fm: FocusMonitor,
  ) {}
  forceFocus = new EventEmitter(true);
  ngOnInit() {
    if (!this.allowEdit) {
      this.group.disable();
    }
    this.prepareForm(this.entry);
    combineLatest([
      this.fm.monitor(this.ref, true).pipe(debounceTime(500)),
      this.groupValue$.pipe(distinctUntilChangedJson()),
      this.forceFocus.asObservable().pipe(
        startWith(Date.now() - 1000),
        map((x) => (x ? x : Date.now() + 1000)),
        distinctUntilChangedJson(),
      ),
    ])
      .pipe(
        untilDestroyed(this),
        filter(
          ([focus, data, force]) =>
            ((force && force > Date.now()) || focus === null) &&
            !this.isLoading &&
            this.group.valid &&
            this.dialog.openDialogs.length === 0,
        ),
        distinctUntilChangedJson(([, value]) => value),
        debounceTime(100),
        switchMap(([, value]) => {
          return this.updateTime(value).catch((err) => {
            this.isLoading = false;
            this.prepareForm(this.entry);
            log.error(err);
            return EMPTY;
          });
        }),
        catchError((err) => {
          log.error(err);
          return EMPTY;
        }),
        filter((x) => !!x?.[0]),
      )
      .subscribe({
        next: ([time]: Time[]) => {
          this.appService.notifySuccess('success.updated');
          log.debug(time);
          this.isLoading = false;
          if (time) this.prepareForm(time);
        },
        error(err) {
          log.error(err);
        },
      });
    this.entry$disabled.subscribe((x) => {
      if (x !== this.group.disabled) this.group[!x ? 'disable' : 'enable']();
    });
  }
  ngAfterContentInit() {}
  ngAfterViewInit(): void {}
  prepareForm(x: Time) {
    const mode = this.mode;
    const { start, end, duration } = {
      start: flow(format('HH:mm'))(Date.parse(x.start)) as string,
      end: flow(format('HH:mm'))(Date.parse(x.end)) as string,
      duration: parseDurationAsFormat(x.timeDiff / 60),
    };
    if (mode === 'range_optional') this._stateVisibleMode.value = x['inputMode'] !== 'duration';
    this.group.patchValue(
      {
        name: x.name,
        project: x.project,
        task: x.task,
        tags: x.tags || [],
        billable: !!x.billable,
        time: {
          start,
          end,
          duration,
        },
      },
      { emitEvent: false },
    );
    ((group: UntypedFormGroup) => {
      if (mode !== 'range') {
        group.controls.start.clearValidators(),
          group.controls.end.clearValidators(),
          group.controls.duration.clearValidators();
      } else {
        group.controls.start.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]),
          group.controls.end.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]),
          group.controls.duration.setValidators([Validators.required, CustomValidators.validDuration()]);
      }
    })(this.group.controls.time as UntypedFormGroup);
    this.group.setMustChange();
    this.group.updateValueAndValidity();
    if (x.currentUserCanEdit === false) {
      this.group.disable();
    }
    this.cdref.detectChanges();
  }
  updateTime(value: any) {
    this.isLoading = true;
    const refDate = new Date(this.entry.start);
    const [newStart, newEnd] = [value.time.start, value.time.end].map((d) => parse(d, 'HH:mm', refDate));
    const mode = this.mode;
    if (mode !== 'duration') {
      let _start = newStart,
        _end = newEnd;
      let start = new Date(new Date(refDate).setHours(_start.getHours(), _start.getMinutes(), 0, 0)),
        end = new Date(new Date(refDate).setHours(_end.getHours(), _end.getMinutes(), 0, 0));
      if (end < start) {
        end = new Date(start.getTime());
      }
      if ([start, end].some((x) => !isValid(x))) {
        throw new Error('Invalid Start / End Values');
      }
      if (isAfter(start, end)) throw new Error('Invalid Start / End Values');
      const inputMode = mode === 'range_optional' && !this.stateVisibleMode ? 'duration' : 'range';
      const nextDuration =
        inputMode === 'duration'
          ? parseDurationSeconds(value.time.duration)
          : (({ hours, minutes }) => hours * 60 + minutes)(intervalToDuration({ start, end })) * 60;
      return new Promise<Time[]>((resolve, reject) =>
        this.myTimesService
          .update({
            ...this.entry,
            name: value.name,
            tags: value.tags || [],
            billable: !!value.billable,
            project: value.project,
            // @ts-ignore
            inputMode,
            ...(inputMode === 'duration'
              ? {
                  start: startOfDay(start.getTime()).toISOString(),
                  end: startOfDay(start.getTime()).toISOString(),
                  timeDiff: nextDuration,
                }
              : { start: start.toISOString(), end: end.toISOString() }),
            task: value.task,
          })
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe({
            next: ([x]) => {
              this.recordService.handleSuccess(x);
              resolve([x]);
            },
            error: (err) => {
              this.recordService.handleError(err);
              reject(err);
            },
          }),
      );
    } else {
      const newDuration = parseDurationSeconds(value.time.duration);
      return new Promise<Time[]>((resolve, reject) =>
        this.myTimesService
          .update({
            ...this.entry,
            name: value.name,
            tags: value.tags || [],
            billable: !!value.billable,
            project: value.project,
            timeDiff: newDuration,
            task: value.task,
          })
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe({
            next: ([x]) => {
              this.recordService.handleSuccess(x);
              resolve([x]);
            },
            error: (err) => {
              this.recordService.handleError(err);
              reject(err);
            },
          }),
      );
    }
  }
  get isManualMode() {
    return this.recordService.selectedRecordMode === SelectMode.Manual;
  }
  readonly isManualMode$ = this.recordService.selectedRecordMode$.pipe(map((x) => x === SelectMode.Manual));
  getProjectColor(id: string) {
    return this.projectsQuery.getEntity(id)?.color;
  }
  isDefaultProject(id: string) {
    return this.projectsQuery.getEntity(id)?.useAsDefault === true;
  }
  @Output()
  Deleted = new EventEmitter<string>(true);
  @Output()
  DateUpdate = new EventEmitter<string>(true);
  deleteEntry(entry: Time) {
    this.isLoading = true;
    this.myTimesService
      .delete(entry)
      .pipe(
        catchError((err) => {
          this.isLoading = false;
          return of(err);
        }),
        finalize(() => {
          this.appService?.notifySuccess('success.deleted');
        }),
      )
      .subscribe({
        next(x) {
          this.Deleted?.next(entry.id);
          this.Deleted?.complete();
        },
        error: (err) => this.recordService.handleError(err),
      });
  }

  selectInput(ev: Event, timeInput: ElRefDirective) {
    const el: HTMLElement = timeInput.elementRef.nativeElement;
    if (!el) {
      return;
    }
    const input = el.querySelector('input');
    if (input) {
      ev.preventDefault();
      input.select();
    }
  }
  openTagPicker() {
    if (this.group.disabled) return;
    const entry = this.entry;
    this.dialog
      .open(TagPickerDialogComponent, {
        data: <TagDialogData>{
          data: {
            SelectedTags: entry.tags ? [...(entry.tags as any)] : [],
            canToggle: true,
            type: TagType.Time,
            allowTypeModify: false,
          },
        },
        restoreFocus: false,
      })
      .afterClosed()
      .pipe(map((x) => x || []))
      .subscribe((x) => {
        this.group.patchValue({
          tags: [...x],
        });
      });
  }
  toggleBilled() {
    if (this.group.disabled) return;
    this.group.patchValue({
      billable: !this.group.value.billable,
    });
    setTimeout(() => {
      this.forceFocus.emit();
    }, 100);
  }
  readonly projectTooltipData$ = this.entry$.pipe(
    map((val) => {
      return {
        project: val.project,
        client: val.client,
        task: val.task,
        tags: val.project?.tags,
      };
    }),
  );
  get timeFormat() {
    return this.appService.timeFormat;
  }
  get timeZone() {
    return this.userSettingsQuery.getValue().settings.timeZone;
  }
  get currentLang() {
    return this.i18nService.currentLang;
  }
  copyEntry(time: Time) {
    if (time.project.completed) return;
    if (this.appService.isMobile) {
      this.dialog.open(TimeTrackerCalendarStepperCreateDialogComponent, {
        data: <TimeTrackCreateData>{
          billable: time.billable,
          title: time.name,
          project: time.project,
          task: time.task,
          tags: time.tags,
        },
      });
      return;
    }
    return this.recordService.copyRecording(time);
  }
  continueEntry(time: Time) {
    return this.recordService.continueRecording(time);
  }
  private dialogOpen = false;
  openProjectPicker() {
    if (this.group.disabled || this.isLoading) return;
    const { project, task } = this.group.value;
    this.dialogOpen = true;
    this.dialog
      .open(ClientProjectPickerDialogComponent, {
        restoreFocus: true,
        data: <ClientProjectDialogData>{
          data: {
            selectedProject: project,
            selectedTask: task,
            defaultProject: this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault, limitTo: 1 })[0],
            closeOnRemove: true,
            canToggle: true,
          },
        },
      })
      .afterClosed()
      .pipe(tap(() => (this.dialogOpen = false)))
      .pipe(filter((x) => !!x))
      .pipe(filter(([x, t]: [Project, Task]) => !!x && (x.id !== project.id || t?.id !== task?.id)))
      .subscribe(([x, t]) => {
        this.group.patchValue({
          project: x,
          task: t,
        });
      });
  }
  editTime(time: Time) {
    this.dialog.open(TimeTrackerCalendarUpdateDialogComponent, {
      data: {
        title: time.name,
        start: new Date(time.start),
        end: new Date(time.end),
        meta: { time },
      },
    });
  }
  allowedDate() {
    const currentuser = this.userSettingsQuery.getValue();
    const blockTime = currentuser.workspace.settings.blockTimes;
    const mode: string = blockTime.pastMode;
    const isAdmin = !!currentuser.workspace.users.find((x) => x.admin && x.id === currentuser.id);
    let allowedDate;
    if (isAdmin) return null;
    if (mode === 'none') {
      return allowedDate;
    } else if (mode == 'date') {
      allowedDate = blockTime.pastDate;
    } else {
      const period = blockTime.period;
      const range: string = blockTime.range;
      allowedDate = startOfDay(new Date());
      if (range === 'day') {
        allowedDate.setDate(allowedDate.getDate() - period);
      } else if (range === 'week') {
        allowedDate.setDate(allowedDate.getDate() - period * 7);
      } else if (range === 'month') {
        allowedDate.setMonth(allowedDate.getMonth() - period);
      }
    }
    return allowedDate;
  }
  openCalPicker(time: Time) {
    return this.dialog
      .open(TimeDatePickerComponent, {
        data: <TimeDatePickerConfig>{
          selectedDate: new Date(time.start),
          minDate: new Date(this.allowedDate()),
        },
      })
      .afterClosed()
      .pipe<Date, any>(
        filter((x) => isValid(x)),
        switchMap((x) => {
          const start = new Date(time.start),
            end = new Date(time.end);
          const newStart = new Date(
              x.setHours(start.getHours(), start.getMinutes(), start.getSeconds(), start.getMilliseconds()),
            ),
            newEnd = new Date(x.setHours(end.getHours(), end.getMinutes(), end.getSeconds(), end.getMilliseconds()));
          return defer(() => {
            this.isLoading = true;
            return this.myTimesService
              .update({ ...time, start: newStart.toISOString(), end: newEnd.toISOString() })
              .pipe(finalize(() => (this.isLoading = false)));
          });
        }),
      )
      .subscribe(
        ([x]) => this.recordService.handleSuccess(x),
        (err) => this.recordService.handleError(err),
      );
  }
}
