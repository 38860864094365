import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-workspace-change-confirm-dialog',
  templateUrl: './workspace-change-confirm-dialog.component.html',
  styleUrls: ['./workspace-change-confirm-dialog.component.scss'],
})
export class WorkspaceChangeConfirmDialogComponent implements OnInit {
  constructor(private ref: MatDialogRef<WorkspaceChangeConfirmDialogComponent>) {}

  ngOnInit(): void {
    this.ref.updateSize('460px');
  }
  close(data: any) {
    return this.ref.close(data);
  }
}
