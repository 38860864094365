import { Component, OnInit } from '@angular/core';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { map } from 'rxjs/operators';
import { MyTimesQuery, Time } from 'timeghost-api';

@Component({
  selector: 'app-home-mobile-controls',
  templateUrl: './home-mobile-controls.component.html',
  styleUrls: ['./home-mobile-controls.component.scss'],
})
export class HomeMobileControlsComponent implements OnInit {
  readonly timeRunning$ = this.myTimeQuery
    .selectAll({ filterBy: (x) => !x.end, limitTo: 1 })
    .pipe(map((times) => times[0]));
  constructor(private myTimeQuery: MyTimesQuery, private recordService: RecordToolbarService) {}
  isLoading = false;
  ngOnInit(): void {}
  openCreateDialog() {
    return this.recordService.openCreateDialog();
  }
  openUpdateDialog(time: Time, forceUpdate: boolean = false) {
    return this.recordService.openUpdateDialog(time, forceUpdate);
  }
  startRecord() {
    return this.recordService.startRecord();
  }
  stopRecord() {
    return this.recordService.stopRecord();
  }
}
