import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { createRxValue, distinctUntilChangedJson } from '@app/_helpers/utils';
import { UtilService } from '@app/_services/util.service';
import { FadeInOut, SlideUpDown } from '@app/animations/fade';
import { AppService } from '@app/app.service';
import { I18nService } from '@app/core';
import { HomeService } from '@app/pages/home-page/home.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { areIntervalsOverlapping, format, isValid, isWithinInterval, startOfDay } from 'date-fns/esm';
import { differenceInSeconds, startOfDay as startOfDayFp, subDays } from 'date-fns/esm/fp';
import { maxBy, minBy } from 'lodash-es';
import { flow, groupBy, orderBy, sum, toPairs } from 'lodash/fp';
import { MediaObserver } from 'ngx-flexible-layout';
import { BehaviorSubject, Observable, Subject, combineLatest, forkJoin, of } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, startWith, switchMap } from 'rxjs/operators';
import { firstBy } from 'thenby';
import {
  Logger,
  MyTimesQuery,
  MyTimesService,
  ProjectsQuery,
  Time,
  UserSettings,
  UserSettingsQuery,
} from 'timeghost-api';

import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { LoaderComponent } from '../loader/loader.component';

const log = new Logger('TimeTrackerTableComponent');
export enum TableDisplayType {
  Grouped = 'grouped',
  Simple = 'simple',
  Calendar = 'calendar',
  ComeGo = 'comego',
}
export type Usage = { used: number; max: number };
@UntilDestroy()
@Component({
  selector: 'app-time-tracker-table',
  templateUrl: './time-tracker-table.component.html',
  styleUrls: ['./time-tracker-table.component.scss'],
  animations: [FadeInOut, SlideUpDown],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'time-table-host',
  },
})
export class TimeTrackerTableComponent implements OnInit, OnDestroy {
  constructor(
    private userSettingsQuery: UserSettingsQuery,
    private myTimesService: MyTimesService,
    private myTimesQuery: MyTimesQuery,
    private projectsQuery: ProjectsQuery,
    private lang: I18nService,
    private appService: AppService,
    private media: MediaObserver,
    private home: HomeService,
  ) {}
  isMobile$ = this.media.asObservable().pipe(map((x) => x.findIndex((m) => ['xs', 'sm'].includes(m.mqAlias)) !== -1));
  @HostBinding('class')
  @Input()
  class: string = '';
  @HostBinding('style')
  @Input()
  style: string = '';
  private _timeEntries = new BehaviorSubject<Time[]>(null);
  @Output('OnTimeEntriesUpdate')
  readonly timeEntries$ = this._timeEntries.asObservable().pipe(distinctUntilChanged());
  @Input()
  get timeEntries() {
    return this._timeEntries.getValue();
  }
  set timeEntries(val: Time[]) {
    this._timeEntries.next(val);
  }
  readonly _range = createRxValue<DateRange<Date>>(null);
  @Input()
  set range(value: DateRange<Date>) {
    this._range.update(value);
  }
  @Input()
  emptyTemplate: TemplateRef<any>;
  @Input()
  useCustomTimeEntries: boolean = false;
  private selectOverlaps$ = this.myTimesQuery.selectAll().pipe(
    distinctUntilChangedJson((x) => x?.map(({ start, end }) => ({ start, end })) ?? {}),
    map((times) => {
      return times
        .filter(({ start, end }) => isValid(new Date(start)) && isValid(new Date(end)))
        .filter((t, ti, arr) => {
          const start = new Date(t.start),
            end = new Date(t.end);
          try {
            return !!arr.find((x) => {
              let [xStart, xEnd]: [Date, Date] = [null, null];
              return (
                x.id !== t.id &&
                (xStart = new Date(x.start)) &&
                (xEnd = new Date(x.end)) &&
                isValid(xStart) &&
                isValid(xEnd) &&
                areIntervalsOverlapping({ start, end }, { start: xStart, end: xEnd })
              );
            });
          } catch {
            return false;
          }
        });
    }),
  );
  readonly times$ = combineLatest([
    this.myTimesQuery.selectAll(),
    this.selectOverlaps$,
    this._range.asObservable().pipe(debounceTimeAfterFirst(80)),
  ]).pipe(
    map(([x, overlaps, range]) =>
      x
        .filter((t) => t.start && t.end && isWithinInterval(Date.parse(t.start), range))
        .map((t) => ({ ...t, hasTimeOverlap: !!overlaps.find((o) => o.id === t.id) })),
    ),
    map((x) => this.parseTime(x)),
    map((x) => (x?.length > 0 ? x : null)),
  );
  readonly isLoading$ = this.myTimesQuery.selectLoading().pipe(distinctUntilChanged());

  @Input()
  showCompleted: Observable<boolean> = of(false);
  @Input()
  showMonthDivider: boolean = false;
  //#endregion
  readonly selectedDisplayMode$ = this.userSettingsQuery.select((x) => x.settings.tableDisplay);
  get currentLanguage() {
    return this.lang.language || '';
  }
  private getTimes(entry: EntryItem) {
    return entry.times.map((x): Time[] => x.times).reduce((x, y) => x.concat(y));
  }
  simpleTableSort(entries: { times: Time[] }[]) {
    return entries
      .map((x) => x.times)
      .reduce((l, r) => [...l, ...r], [])
      .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
  }
  isDefaultProject(id: string) {
    return this.projectsQuery.getEntity(id)?.useAsDefault === true;
  }
  get timeZone() {
    return this.userSettingsQuery.getValue().settings.timeZone;
  }
  getProjectColor(id: string) {
    return this.projectsQuery.getEntity(id)?.color;
  }
  readonly entryDateChange = new BehaviorSubject<string>('');
  private parseTime(x: Time[]) {
    return flow(
      (times: Time[]) => times.filter((y) => y.start && y.end).sort(firstBy('start', 'desc').thenBy('end', 'desc')),
      groupBy((t: Time) => startOfDay(new Date(t.start)).toISOString()),
      toPairs,
      (a: [string, Time[]][]) =>
        a.map(([pid, times], index): EntryItem => {
          let md = new Date(pid);
          let ret = flow(
            groupBy((t: Time) => {
              let st = minBy(
                times.filter((y) => y.task && t.task && y.task.id === t.task.id),
                (y) => y.start,
              )?.start;
              let ed = maxBy(
                times.filter((y) => y.task && t.task && y.task.id === t.task.id),
                (y) => y.end,
              )?.end;
              // @ts-ignore
              return `${t.project?.id}${t.task?.id}|${st + ed}|${t.billable}|${t.name}|${t.inputMode}`;
            }),
            toPairs,
            (b) =>
              b.map(([key, t]: [string, Time[]]) => {
                let id = key;
                let minMax = {
                  min: minBy(t, (_t: Time) => _t.start),
                  max: maxBy(t, (_t: Time) => _t.end),
                  sum: t.reduce((timeDiff, r) => (timeDiff += ~~r.timeDiff), 0),
                };
                return {
                  id: id,
                  times: t.sort(firstBy('start', 'desc').thenBy('end', 'desc')),
                  billable: t.findIndex((_t: Time) => _t.billable) !== -1,
                  task: minMax.min?.task ?? { id: 0, name: null },
                  ...minMax,
                };
              }),
          )(times.filter((t) => t.start && t.end));
          const edit = this.VerifyBlockTimePast(this.userSettingsQuery.getValue(), md);
          return {
            id: parseInt(pid, 0),
            day: md.toISOString(),
            times: ret,
            date: md,
            state: {
              showMonthDivider: this.showMonthDivider,
              month: format(md, 'MMMM'),
              fullDay: format(md, 'dddd'),
            },
            allowEdit: edit,
          };
        }),
      orderBy(['date'], 'desc'),
      (a: EntryItem[]) =>
        a.map((y, index, arr) => {
          const prev = arr[index - 1];
          if (prev && y.state.showMonthDivider) {
            y.state.showMonthDivider = prev.state.month !== y.state.month;
          }
          return y;
        }),
    )(x);
  }
  private _byDayTreshold = new BehaviorSubject<number>(31);

  @Output()
  readonly byDayTreshold$ = this._byDayTreshold.asObservable().pipe(
    distinctUntilChanged(),
    filter((x) => !!x && x > 0 && x <= 365),
  );
  get byDayTreshold() {
    return this._byDayTreshold?.getValue();
  }
  @Input()
  set byDayTreshold(val: number) {
    this._byDayTreshold?.next(val);
  }
  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  isAMPM$ = this.userSettingsQuery
    .select((x) => x.settings.timeFormat24h)
    .pipe(
      startWith(this.userSettingsQuery.getValue().settings.timeFormat24h),
      map((x) => x === false),
    );
  showLoadMore: boolean;
  readonly getSpanSinceThreshold$ = this.byDayTreshold$.pipe(
    map((x) => ({
      start: flow(subDays(x), startOfDayFp)(new Date()),
      end: flow(startOfDayFp)(new Date()),
    })),
  );
  ngOnInit() {
    this.selectedDisplayMode$.subscribe((x) => log.debug(x));
  }
  removeItem() {}
  trackGroup(index: number, entry: EntryItem) {
    return entry.id;
  }
  trackItem(index: number, entry: Time) {
    return entry && entry.id;
  }
  deleteGroup(time: Time[], loader: LoaderComponent) {
    loader.isActive = true;
    of(time)
      .pipe(
        switchMap(() => forkJoin(time.map((y) => this.myTimesService.delete(y)))),
        finalize(() => (loader.isActive = false)),
      )
      .subscribe();
  }
  get timeFormat() {
    return this.appService.timeFormat;
  }
  parseExactDuration(data: { min: Time; max: Time; times: Time[] }) {
    return UtilService.parseMS(sum(data.times.filter((x) => x.timeDiff > 0).map((x) => ~~x.timeDiff)) * 1000, {
      showSeconds: false,
    });
  }
  parseDuration(data: { min: Time; max: Time }) {
    let start = new Date(data.min.start);
    let end = new Date(data.max.end);
    let diff = flow(differenceInSeconds(start))(end);
    if (diff * 1000 < 0) {
      return '-';
    }
    return UtilService.parseMS(diff * 1000, {
      showSeconds: false,
    });
  }
  VerifyBlockTimePast(currentuser: UserSettings, date: Date) {
    const blockTimes = currentuser.workspace.settings.blockTimes;
    const mode: string = blockTimes.pastMode;
    const isAdmin = !!currentuser.workspace.users.find((x) => x.admin && x.id === currentuser.id);
    let allowedDate;
    if (mode === 'none') {
      return true;
    } else if (mode == 'date') {
      allowedDate = new Date(blockTimes.pastDate);
    } else {
      const period = blockTimes.period;
      const range: string = blockTimes.range;
      allowedDate = startOfDay(new Date());
      if (range === 'day') {
        allowedDate.setDate(allowedDate.getDate() - period);
      } else if (range === 'week') {
        allowedDate.setDate(allowedDate.getDate() - period * 7);
      } else if (range === 'month') {
        allowedDate.setMonth(allowedDate.getMonth() - period);
      }
    }
    if (isAdmin) {
      return true;
    }
    if (date >= allowedDate) {
      return true;
    } else {
      return false;
    }
  }
}
export interface EntryItem {
  id: number;
  day: string;
  date: Date;
  times: { id: string; times: Time[]; min: Time; max: Time }[];
  state?: { [key: string]: any };
  allowEdit: boolean;
}
