<ng-container
  *ngTemplateOutlet="
    this.isLoading ? loadingFab : (this.timeRunning$ | async) ? runningFabTimer : normalFabTimer;
    context: { time: (this.timeRunning$ | async) }
  "
></ng-container>
<ng-template #runningFabTimer let-time="time">
  <button mat-raised-button color="accent" (click)="stopRecord()">
    <div class="line-height-base -ml-3 -mr-1 flex h-10 flex-row items-center justify-start gap-1">
      <mat-icon>stop</mat-icon>
      {{ time.start | dfnsParseIso | dfnsDifferenceInSeconds: now() | parseMsAsDuration: true }}
    </div>
  </button>
</ng-template>
<ng-template #normalFabTimer let-time="time">
  <button mat-fab (click)="startRecord()">
    <mat-icon>play_arrow</mat-icon>
  </button>
</ng-template>
<ng-template #loadingFab let-time="time">
  <button mat-fab disabled>
    <mat-progress-spinner [strokeWidth]="3" [diameter]="20" color="accent" [mode]="'indeterminate'">
    </mat-progress-spinner>
  </button>
</ng-template>
