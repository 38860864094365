<h1 matDialogTitle>{{ 'workspace.change-confirm.title' | translate }}</h1>

<mat-dialog-content>
  <div class="mat-body">{{ 'workspace.change-confirm.body' | translate }}</div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="close(true)">
    {{ 'workspace.change-confirm.submit' | translate }}
  </button>
  <button mat-button (click)="close(false)">{{ 'utils.cancel' | translate }}</button>
</mat-dialog-actions>
