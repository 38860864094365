<mat-card class="pos-relative group-wrap rounded-inherit">
  <mat-card-title-group class="group-header flex flex-row items-start justify-start">
    <mat-card-title class="group-date">{{ data.date | dfnsFormat: 'iiii' }}</mat-card-title>
    <mat-card-subtitle class="group-date-full">{{ data.date | formatDate: 'PP' }}</mat-card-subtitle>
    <div class="flex-auto"></div>
    <div
      class="flex items-center space-x-6"
      *ngIf="{
        schedule: (schedule$ | async),
        stats: (data$stats | async),
      } as info"
    >
      <ng-container *ngIf="!data.allowEdit as blockedit">
        <div class="flex h-10 w-10 items-center justify-center" matTooltip [matTooltipTemplate]="blockEdit">
          <mat-icon color="accent">lock_clock</mat-icon>
        </div>
      </ng-container>
      <ng-container>
        <ng-container *ngIf="info.schedule as sched">
          <ng-container
            *ngTemplateOutlet="
              scheduleView;
              context: {
                $implicit: sched,
                isProjectTab: !(comegoEnabled$ | async),
              }
            "
          ></ng-container>
        </ng-container>
        <div class="mat-bg-5 h-8 w-px" *ngIf="info.schedule && info.stats"></div>
      </ng-container>
      <div
        *ngIf="info.stats as item"
        class="flex cursor-default flex-col"
        matTooltip
        [matTooltipTemplate]="durationHoverCard"
        [matTooltipTemplateContext]="{
          timeDiff: item.timeSum,
          billable: item.billable,
        }"
      >
        <small>
          <strong> {{ item?.timeSum ?? 0 | parseMsAsDuration: false }} </strong>
        </small>
        <small class="mat-hint">
          {{ (item.billable ? item.billable : 0) | parseMsAsDuration: false }}
        </small>
      </div>
    </div>
  </mat-card-title-group>
  <mat-card-content class="--table-time-container">
    <div class="group-content group-content-scroll flex flex-col items-stretch justify-start gap-2">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </div>
  </mat-card-content>
</mat-card>
<ng-template #durationHoverCard let-timeDiff="timeDiff" let-billable="billable">
  <div class="flex flex-col">
    <span>{{ timeDiff | parseMsAsDuration: true }}</span>
    <span *ngIf="billable" class="mat-text-accent">{{ billable | parseMsAsDuration: true }}</span>
  </div>
</ng-template>
<ng-template #scheduleView let-sched let-isProjectTab="isProjectTab">
  <div
    class="mat-typography mat-bg-5-hover grid h-12 items-center gap-x-6 rounded-lg px-4"
    [ngClass]="{ 'grid-cols-[1fr_28px]': isProjectTab }"
    matTooltip
    [matTooltipTemplate]="scheduleTooltip"
    [matTooltipTemplateContext]="{ $implicit: sched }"
    [matTooltipDisabled]="!isProjectTab"
  >
    <div class="flex flex-col text-right" [ngClass]="{ 'text-sm': isProjectTab }">
      <span>{{ sched.usage | parseMsAsDuration }}</span>
      <span *ngIf="isProjectTab">{{ sched.cap | parseMsAsDuration }}</span>
    </div>
    <div class="mat-circle-card" *ngIf="isProjectTab">
      <mat-progress-spinner
        [value]="sched.percent * 100 | clamp: 0 : 100"
        strokeWidth="3"
        [diameter]="20"
        [color]="sched.graphState"
        class="with-back-spinner"
      ></mat-progress-spinner>
    </div>
  </div>
</ng-template>
<ng-template #scheduleTooltip let-sched>
  <div class="mat-typography flex flex-col px-3 py-2.5">
    <div class="flex items-center space-x-2">
      <span>{{ sched.name || ('schedules.name' | translate) }}</span>
      <ng-container *ngIf="sched.percent >= 0">
        <span class="dot size-1.5"></span>
        <span>{{ sched.percent | percent }}</span>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #blockEdit>
  <div class="flex flex-col space-y-2">
    <div>{{ 'time.blockEdit' | translate }}</div>
  </div>
</ng-template>
